<template>
    <div>
        <h2>{{ $t("invoice.bilan") }}</h2>
        <div class="box">

            <DateRange
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadInvoiceBilan(start_date.toDateInputValue(), end_date.toDateInputValue())"
            />
            <b-button class="col-2" variant="primary" @click.prevent="display_previous_month">{{ $t("action.previous_month") }}</b-button>
            <b-button class="col-2 ml-4" variant="primary" @click.prevent="display_current_month">{{ $t("action.current_month") }}</b-button>

            <CustomTable
                id_table="invoice_bilan"
                :items="bilan"
                :busy.sync="table_busy"
                primaryKey="increment"
                :hide_if_empty="true"
                ref="aValiderTable"
            />
        </div>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'

    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"

    export default {
        name: "Bilan",
        mixins: [Config, TableAction, Invoice, Navigation],
        props: {
            
        },
        data () {
            return {
                processing: false,
                table_busy: true,
                bilan: [],
                start_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                end_date: new Date(),
                events_tab: {
                    
                },
                
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                if(this.getConfig('startDateInvoiceBilan')) {
                    this.start_date = new Date(this.getConfig('startDateInvoiceBilan'))
                }
                if(this.getConfig('endDateInvoiceBilan')) {
                    this.end_date = new Date(this.getConfig('endDateInvoiceBilan'))
                }
                this.loadInvoiceBilan(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },
            async loadInvoiceBilan(start_date, end_date) {
                this.table_busy = true
				this.saveDateFilters()

                const bilan = await this.loadBilan(start_date, end_date)
				await this.formatBilan(bilan)

				this.table_busy = false
            },

			async formatBilan(bilans){
				if(!bilans) {
					return []
				}
				let tab = []

				for (var i = 0; i < Object.keys(bilans).length; i++) {
					tab.push({
						'tiers_rs': bilans[Object.keys(bilans)[i]].author.tiers_rs,
						'autres': bilans[Object.keys(bilans)[i]].autres,
						'facture': bilans[Object.keys(bilans)[i]].facture,
						'impayes': bilans[Object.keys(bilans)[i]].impayes,
						'pensions': bilans[Object.keys(bilans)[i]].pensions,
						'regle': bilans[Object.keys(bilans)[i]].regle,
						'saillie': bilans[Object.keys(bilans)[i]].saillie,
						'currency': bilans[Object.keys(bilans)[i]].author.tiers_currency,
					})
				}

				this.bilan = tab
			},
			
			saveDateFilters() {
                this.setConfig('startDateInvoiceBilan', this.start_date)
                this.setConfig('endDateInvoiceBilan', this.end_date)
			},

            async display_current_month() {
                const now = new Date();
                this.start_date = new Date(now.getFullYear(), now.getMonth(), 1)
                this.end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0)

                await this.loadInvoiceBilan(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },

            async display_previous_month() {
                let date = new Date()
                date.setMonth(date.getMonth() - 1)
                this.start_date = new Date(date.getFullYear(), date.getMonth(), 1)
                this.end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0)


                await this.loadInvoiceBilan(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
        }
    }
</script>
